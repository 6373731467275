import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledLifeAtVisby = styled.section`
	background-color: ${colors.grayLight};
	padding: 68px 0 48px;

	${breakpoint.medium`
		padding: 124px 0 104px;
	`}

	.toptitle {
		margin-bottom: 30px;
		text-align: center;

		${breakpoint.medium`
			margin-bottom: 50px;
		`}

		h2 {
			margin-bottom: 10px;
		}
				
		a {
			width: auto;
			color: ${colors.charcoal};
			margin-top: 20px;

			&:hover {
			  background-color: ${colors.charcoal};
			  color: ${colors.white};
			}
		}	
 	}

	.thumbnails_row {
		display: flex;
		flex-wrap: wrap;
		column-gap: 25px;
		columns: 3;
		margin: 20px auto;
		max-width: 1100px;
		justify-content: center;

		.image_column { 
			padding-bottom: 30px;
			max-width: 300px;
			margin: 5px auto;

			p {
				text-align: center;
			    font-weight: 400;			
				font-size: smaller;
				line-height: 1.5em;			
			}
			
			.title {
				color: ${colors.charcoal};
			    font-weight: 600;	
			    color: ${colors.purpleDark};
				font-size: inherit;			    		
			}
			
		}

		.thumbnail_image { 
			width: 118px;
			margin: 10px auto 20px;
		} 
	}
`

const LifeAtVisby = () => {

  return (
    <StyledLifeAtVisby>
      <Container>
        <div className="toptitle">
			<h2>Life at Visby</h2>
			<p>We believe the best work is done when people are happy, balanced, and inspired. Whether you’re in an office or working remotely it’s our goal to make everyone feel at home.</p>
        </div>

		<div className="thumbnails_row">
			<div className="image_column" data-aos="flip-left" data-aos-duration="300">
				<StaticImage
					src="../../../assets/images/careers/life/stock-options.png"
					width={118}
					layout="fixed"
					alt="Stock options"
					className="thumbnail_image"
				/>
				<p className="title">Stock options</p>
				<p>You are an owner! We are proud to offer stock options to each of our employees.</p>
			</div>
			<div className="image_column" data-aos="flip-right"  data-aos-delay="300" data-aos-duration="500">
				<StaticImage
					src="../../../assets/images/careers/life/competitive.png"
					width={118}
					layout="fixed"
					alt="Competitive benefits package"
					className="thumbnail_image"
				/>
				<p className="title">Competitive benefits package</p>
				<p>Our benefits include comprehensive healthcare coverage, a great 401(k), flexible spending accounts, and so much more.</p>
			</div>
			<div className="image_column" data-aos="flip-left"  data-aos-delay="500" data-aos-duration="700">
				<StaticImage
					src="../../../assets/images/careers/life/eat-well.png"
					width={118}
					layout="fixed"
					alt="Catered meals"
					className="thumbnail_image"
				/>
				<p className="title">Fully Stocked Kitchens</p>
				<p>Eat well and be healthy! Meals, snacks and beverages supplemented by a monthly meal stipend.</p>
			  </div>
			<div className="image_column" data-aos="flip-right" data-aos-delay="700" data-aos-duration="300">
				<StaticImage
					src="../../../assets/images/careers/life/career-dev.png"
					width={118}
					layout="fixed"
					alt="Career development"
					className="thumbnail_image"
				/>
				<p className="title">Career development</p>
				<p>Learning is built into every role here. You’ll get mentorship and take ownership.</p>
			</div>
			<div className="image_column" data-aos="flip-left" data-aos-delay="900" data-aos-duration="500">
				<StaticImage
					src="../../../assets/images/careers/life/everyone-voice.png"
					width={118}
					layout="fixed"
					alt="Everyone has a voice"
					className="thumbnail_image"
				/>
				<p className="title">Everyone has a voice</p>
				<p>Innovation doesn’t know hierarchy. We make sure everyone is heard, considered, and respected.</p>
			</div>
			<div className="image_column" data-aos="flip-right"  data-aos-delay="1100" data-aos-duration="700">
				<StaticImage
					src="../../../assets/images/careers/life/giving-back.png"
					width={118}
					layout="fixed"
					alt="Giving back"
					className="thumbnail_image"
				/>
				<p className="title">Giving back</p>
				<p>Be a catalyst for change!  Visby encourages positive community engagement.</p>
			</div>
		</div>
		
        </Container>
   </StyledLifeAtVisby>
  )
}

export default LifeAtVisby
